import { template as template_e23a774c81ef4421b33a94e869ab88cb } from "@ember/template-compiler";
const FKControlMenuContainer = template_e23a774c81ef4421b33a94e869ab88cb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
