import { template as template_a24d255245e54c1890b44e1597f4dab8 } from "@ember/template-compiler";
const FKText = template_a24d255245e54c1890b44e1597f4dab8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
