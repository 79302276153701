import { template as template_62ff8697db3044f582220e63c7a92469 } from "@ember/template-compiler";
const WelcomeHeader = template_62ff8697db3044f582220e63c7a92469(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
