import { template as template_459370797c4643f7a413a2a3b60d8969 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_459370797c4643f7a413a2a3b60d8969(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
