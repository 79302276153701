import { template as template_4bc647678a2440c6a76d4b9a0b0ab708 } from "@ember/template-compiler";
const FKLabel = template_4bc647678a2440c6a76d4b9a0b0ab708(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
