import { template as template_64421c9b5367439b8c0bd9d8aff3a7ab } from "@ember/template-compiler";
const SidebarSectionMessage = template_64421c9b5367439b8c0bd9d8aff3a7ab(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
